import axios from '@/libs/axios'

export default class Settings {

    /**
     * Function to branches
     * @return Promise
     */
    index() {
        return axios.get(`/WebConfiguraciones/ObtenerConfiguraciones`);    
    }
    
    getByCompany() {
        return axios.get(`/WebConfiguraciones/ObtenerConfiguracionesPorEmpresa`);
    }

    update(payload) {
        return axios.post(`/WebConfiguraciones/Update`, payload);
    }
}
